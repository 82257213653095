import { Button } from "@/components/base/button";
import { PauseCircleIcon, PlayCircleIcon } from "@/components/icons/icons";

interface PlayPauseIconProps {
  isPlaying: boolean;
  togglePlayState: () => void;
}

export const PlayPauseIcon = ({ isPlaying, togglePlayState }: PlayPauseIconProps) => {
  return (
    <div className="play-pause-button__container">
      <Button ariaLabel={isPlaying ? "Pause" : "Play"} onClick={togglePlayState}>
        <div className="play-pause-button__wrapper">
          {isPlaying ? (
            <>
              <PauseCircleIcon color={"white"} size={"small"} />
              <div className="play-pause-button__text">Pause</div>
            </>
          ) : (
            <>
              <PlayCircleIcon color={"white"} size={"small"} />
              <div className="play-pause-button__text">Spill av</div>
            </>
          )}
        </div>
      </Button>
    </div>
  );
};
