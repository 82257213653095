import ContentLoader from "react-content-loader";

interface ButtonSkeletonProps {
  size?: "small" | "medium" | "large";
  width?: number | string;
}

const sizeToHeight = {
  small: 44,
  medium: 68,
  large: 92,
};

export const ButtonSkeleton = ({ size = "small", width = "100%" }: ButtonSkeletonProps) => {
  const height = sizeToHeight[size];

  return (
    <ContentLoader height={height} width={width}>
      <rect height="100%" width="100%" />
    </ContentLoader>
  );
};
