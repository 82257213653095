import React from "react";
import { TrainerCardSkeleton } from "./trainer-card-skeleton";

export const TrainerGridSkeletons = () => (
  <div className="trainer-grid">
    {new Array(6).fill(0).map((_number, index) => (
      // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
      <React.Fragment key={index}>
        <TrainerCardSkeleton />
      </React.Fragment>
    ))}
  </div>
);
