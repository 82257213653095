import ContentLoader from "react-content-loader";

interface HeadingSkeletonProps {
  type: "h1" | "h2" | "h3";
}

export const HeadingSkeleton = ({ type }: HeadingSkeletonProps) => (
  <div>
    {type === "h1" && (
      <h1>
        <ContentLoader viewBox="0 0 150 36" height={36} width={150}>
          <rect width="150" height="36" />
        </ContentLoader>
      </h1>
    )}
    {type === "h2" && (
      <h2>
        <ContentLoader viewBox="0 0 123 21" height={21} width={123}>
          <rect width="123" height="21" />
        </ContentLoader>
      </h2>
    )}
    {type === "h3" && (
      <h3>
        <ContentLoader viewBox="0 0 400 160" height={21} width={123}>
          <rect x="110" y="21" rx="4" ry="4" width="254" height="21" />
        </ContentLoader>
      </h3>
    )}
  </div>
);
