"use client";
import { MediaQuery } from "@/contexts/media-context";
import type { MediaFragment, ResponsiveMedia, ResponsiveMediaFragment } from "@/generated/client.generated";
import { classNames } from "@/utils/styling-utils";
import type { ReactNode } from "react";
import { Link } from "../base/link";
import type { WidthType } from "../layout/layout.model";
import { Image } from "./image";
import { Text } from "./text";
import { VideoPlayer } from "./video-player";

interface MediaAllProps {
  openUrlInNewTab?: boolean;
  url?: string;
}

interface MediaProps extends MediaAllProps {
  autoplay?: boolean;
  controls?: boolean;
  fallbackAlt: string;
  gradient?: boolean;
  media?: MediaFragment | ResponsiveMediaFragment;
  priority?: boolean; // specific for Image
  width?: WidthType | number;
}

export const Media = ({
  autoplay,
  controls,
  fallbackAlt,
  media,
  openUrlInNewTab,
  priority = false,
  url,
  width,
  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
}: MediaProps) => {
  // Transform media of type MediaFragment to ResponsiveMediaFragment
  const responsiveMedia =
    media?.__typename !== "ResponsiveMedia" ? ({ desktop: null, mobile: media } as ResponsiveMedia) : media;

  const mediaToDisplayDesktop = responsiveMedia.desktop ? responsiveMedia.desktop : responsiveMedia.mobile;
  const mediaToDisplayMobile = responsiveMedia.mobile;

  // svg-filer må ha en definert bredde for å bli vist i Firefox
  // gjør om bredde satt i pixels i Strapi til rem
  const setWidth = width && width !== "full" ? `${width / 10}rem` : undefined;

  if (!mediaToDisplayMobile) {
    return <></>;
  }

  return (
    <div
      className={classNames("media", width === "full" && "media__width--full")}
      style={{
        ...(setWidth && { maxWidth: setWidth, width: "100%" }),
      }}
    >
      <MediaWrapper
        alt={mediaToDisplayMobile?.alternativeText ?? fallbackAlt}
        url={url}
        openUrlInNewTab={openUrlInNewTab}
      >
        <MediaQuery lessThan="lg">
          {mediaToDisplayMobile?.__typename === "Video" ? (
            <VideoPlayer
              autoplay={autoplay}
              caption={mediaToDisplayMobile.caption ?? undefined}
              url={mediaToDisplayMobile?.url ?? ""}
              thumbnail={responsiveMedia.thumbnailForMobileVideo ?? undefined}
              controls={controls}
            />
          ) : (
            <>
              <Image
                alt={mediaToDisplayMobile?.alternativeText ?? fallbackAlt}
                priority={priority}
                src={mediaToDisplayMobile?.url ?? ""}
                width={mediaToDisplayMobile.width}
                height={mediaToDisplayMobile.height}
                placeholder={mediaToDisplayMobile.placeholder ?? undefined}
              />
              {mediaToDisplayMobile.caption && <Text color="gray" size="small" value={mediaToDisplayMobile.caption} />}
            </>
          )}
        </MediaQuery>

        <MediaQuery greaterThanOrEqual="lg">
          {mediaToDisplayDesktop?.__typename === "Video" ? (
            <VideoPlayer
              autoplay={autoplay}
              caption={mediaToDisplayDesktop.caption ?? undefined}
              url={mediaToDisplayDesktop.url}
              thumbnail={responsiveMedia.thumbnailForDesktopVideo ?? undefined}
              controls={controls}
            />
          ) : (
            <>
              <Image
                alt={mediaToDisplayDesktop?.alternativeText ?? fallbackAlt}
                priority={priority}
                src={mediaToDisplayDesktop?.url ?? ""}
                width={mediaToDisplayDesktop?.width ?? responsiveMedia.mobile?.width}
                height={mediaToDisplayDesktop?.height ?? responsiveMedia.mobile?.height}
                placeholder={mediaToDisplayDesktop?.placeholder ?? undefined}
              />
              {mediaToDisplayDesktop?.caption && (
                <Text color="gray" size="small" value={mediaToDisplayDesktop.caption} />
              )}
            </>
          )}
        </MediaQuery>
      </MediaWrapper>
    </div>
  );
};

interface MediaWrapperProps extends MediaAllProps {
  alt: string;
  children: ReactNode;
}

const MediaWrapper = ({ children, openUrlInNewTab, url }: MediaWrapperProps) =>
  url ? (
    <Link href={url} newTab={openUrlInNewTab} textDecoration="none">
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
