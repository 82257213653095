import { classNames } from "@/utils/styling-utils";
import type { ReactNode } from "react";

interface PTInfoOverlayProps {
  children: ReactNode;
  solidBgColor?: boolean;
}

export const PTInfoOverlay = ({ children, solidBgColor }: PTInfoOverlayProps) => (
  <div className={classNames("pt-info-overlay", solidBgColor && "pt-info-overlay--solid-bg-color")}>{children}</div>
);
