"use client";
import { Image } from "@/components/ui/image";
import { PlayPauseIcon } from "@/components/ui/play-pause-icon";
//TODO remove workaround based on https://github.com/cookpete/react-player/issues/1436
import { Text } from "@/components/ui/text";
import type { ImageFragment } from "@/generated/client.generated";
import { useState } from "react";
import ReactPlayer from "react-player";

interface VideoPlayerProps {
  autoplay?: boolean;
  caption?: string;
  controls?: boolean;
  url: string;
  thumbnail?: ImageFragment;
}

export const VideoPlayer = ({ caption, controls = true, url, thumbnail }: VideoPlayerProps) => {
  const [playState, setPlayState] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const fallbackImage = thumbnail?.url ? (
    <Image
      alt={thumbnail.alternativeText || "thumbnail"}
      src={thumbnail.url}
      placeholder={thumbnail.placeholder ?? undefined}
      width={thumbnail.width}
      height={thumbnail.height}
    />
  ) : undefined;

  return (
    <div className="video-player">
      <ReactPlayer
        muted={true}
        onReady={() => setIsReady(true)}
        playing={playState}
        loop={true}
        controls={controls}
        width="100%"
        height="100%"
        url={url}
        fallback={fallbackImage}
        light={fallbackImage}
        playIcon={
          <div className="video-player__icons">
            <PlayPauseIcon isPlaying={playState} togglePlayState={() => setPlayState(true)} />
          </div>
        }
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
              playsInline: true,
            },
            hlsOptions: {
              capLevelToPlayerSize: true,
              startLevel: -1,
              useDevicePixelRatio: true,
              useNetworkInformationApi: true,
            },
          },
        }}
        playsinline={true}
      />
      {caption && <Text color="gray" size="small" value={caption} />}
      {!controls && isReady && (
        <div className="video-player__icons">
          <PlayPauseIcon isPlaying={playState} togglePlayState={() => setPlayState((prev) => !prev)} />
        </div>
      )}
    </div>
  );
};
